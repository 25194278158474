import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { get } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Button } from "antd";

import Pages from "../../components/Pages";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import { Breadcrumbs } from "../../components/Layout";
import { Video } from "../../components/Media";
import { Share } from "../../widgets";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { VIDEO_CATEGORIES_MAX_UPDATE, VIDEO_CATEGORIES_LIST } from "../../queries/queries.graphql";

export const query = graphql`
	query videoCategoriesList($slug: String!) {
		hasura {
			...VideoCategoryList
		}
	}
`;

export default function VideosCategory({ data, pageContext }) {
	const { theme } = useThemeContext();
	const limit = 6;
	const pageSlug = get(pageContext, "slug", "");
	const maxUpdated = new Date(get(data, "hasura.media_video_categories_aggregate.aggregate.max.updated_at", new Date()));

	const [videos, setVideos] = useState(get(data, "hasura.media_video_categories[0].media_video_categories_media_videos", []));
	const [category, setCategory] = useState(get(data, "hasura.media_video_categories[0]", {}));
	const [offset, setOffset] = useState(limit);

	const videosOptions = {
		displayName: "VideosQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(
		VIDEO_CATEGORIES_MAX_UPDATE,
		{
			variables: {
				slug: pageSlug,
			},
			...videosOptions,
		},
	);

	const [loadMore, { called: loadMoreCalled, loading: loadMoreLoading, error: loadMoreError, data: loadMoreData }] = useLazyQuery(
		VIDEO_CATEGORIES_LIST,
		{
			variables: {
				limit,
				offset,
				slug: pageSlug,
			},
			...videosOptions,
		},
	);

	const [loadVideos, { called: videosCalled, loading: videosLoading, data: videosData, error: videosError }] = useLazyQuery(
		VIDEO_CATEGORIES_LIST,
		{
			variables: {
				limit,
				offset: 0,
				slug: pageSlug,
			},
			...videosOptions,
		},
	);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_media_video_categories_updated_at[0].updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadVideos();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load VideosMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (videosData && !videosError) {
			setVideos(get(videosData, "media_video_categories[0].media_video_categories_media_videos", videos));
			setCategory(get(videosData, "media_video_categories[0]", category));
			removePreloader(videosCalled);
		} else if (videosError) {
			console.error("Invalid load VideosData", { videosError });
			removePreloader();
		}
	}, [videosLoading]);

	useEffect(() => {
		if (loadMoreCalled && !loadMoreError && !loadMoreLoading) {
			setVideos([...videos, ...get(loadMoreData, "media_video_categories[0].media_video_categories_media_videos", [])]);
			setOffset(get(loadMoreData, "media_video_categories[0].media_video_categories_media_videos", []).length < limit ? null : offset + limit);
		} else if (loadMoreError) {
			setOffset(null);
		}

		removePreloader(loadMoreCalled);
	}, [loadMoreLoading]);

	const url = "/media/videos";
	const title = get(category, "title_full", "Видеотека");

	return (
		<Pages
			seo={{}}
			url={url}
		>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={get(pageContext, "title_full", "")}
						pageContext={
							{
								item: {
									title_full: "Медиатека",
									slug: "media",
								},
							}
						}
					/>
				</div>
			</ContentBlock>

			<ContentBlock mount={videos && videos.length} key={"main-content"}>
				<div className={"container"}>
					<div className="row">
						<TextBlock title={title} className={"pb-0"} />
					</div>
					<div className={"row"}>
						{
							videos.map((item, idx) => {
								const src = get(item, "media_video.src", "");
								const slug = get(item, "media_video.slug", "");
								const title_full = get(item, "media_video.title_full", "");

								return (
									<Video
										key={`video-libraryy-item-${idx}`}
										videoSrcURL={src}
										slug={slug}
										videoTitle={title_full}
										className={"col-12 col-md-6"}
									/>
								);
							})
						}
					</div>
				</div>
				<div className="d-flex justify-content-center pb-5">
					<Button
						type={"primary"}
						disabled={loadMoreLoading || !offset}
						loading={loadMoreLoading}
						onClick={() => loadMore({
							variables: {
								offset,
							},
						})}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.body,
							backgroundColor: theme.color.text,
						})}
					>
						{showMore}
					</Button>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Видеотека"} />
			</div>

		</Pages>
	);
}

VideosCategory.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

VideosCategory.defaultProps = {
	data: {},
	pageContext: {},
};
